import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const WorkItem = ({ item }) => {
  const { title, slug, smallImage, tags, date } = item.frontmatter
  return (
    <div className="sm:w-1/2  md:sm:w-1/3 lg:w-1/4 flex-auto flex-no-grow work-item relative justify-start">
      <Link to={`/${item.fields.slug}`} className="relative block">
        {smallImage && <Img fluid={smallImage.childImageSharp.fluid} />}
        <div className="absolute bg-white p-2 pr-3 pin-b flex flex-col">
          <h2 className=" font-impact text-black">{title}</h2>
          <p className="text-grey font-serif">
            {tags && <span>{tags} |</span>} {date}
          </p>
        </div>
      </Link>
    </div>
  )
}

export default WorkItem
