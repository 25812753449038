import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import WorkItem from '../components/workItem'

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "monument.jpg" }) {
      ...heroImage
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date, frontmatter___demote], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            date
            smallImage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const WorkPage = () => (
  <Layout>
    <SEO
      title="Our Work | amillionmonkeys"
      description="We've built apps and websites for a range of different clients. Here are a few of our favourites."
    />
    <StaticQuery
      query={query}
      render={data => (
        <React.Fragment>
          <div className="">
            <Hero image={data.hero.childImageSharp.fluid}>
              <div className="max-w-md m-auto px-4">
                <p className="text-3xl text-white text-center  font-serif text-shadow">
                  We've built apps and websites for a range of different clients. Here are a few of our favourites...
                </p>
              </div>
            </Hero>
          </div>
          <div className="sm:flex flex-wrap work-list justify-start items-start ">
            {data.allMarkdownRemark.edges
              .filter(item => item.node.frontmatter.smallImage)
              .map(item => (
                <WorkItem key={item.node.fields.slug} item={item.node} />
              ))}
          </div>
        </React.Fragment>
      )}
    />
  </Layout>
)

export default WorkPage
